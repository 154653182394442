@import '../../App.scss';

.About{ 
    max-width: 1000px !important;

    .description{
        display: flex;

        
        
        ul.first_list {
            column-count: 2;
        }

        img{
            height: 20em;
            margin-left: 4em;
            border-radius: 10em;
            
        }
    }
}

