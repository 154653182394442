
.Contact{
    max-width: 800px !important;
   .wide{
       width: calc(100% - 1em);

   }

   .contactForm{
       width: 100%;
   }

   .row1{
    display: flex;
    justify-content: stretch;

    .textField{
        flex-grow: 1;
    }
   }

   .textField{
       margin: .5em .5em;
   }
}