$background: rgb(32, 32, 32);
$accent:  #0e94ff;

.App {
  font-size: 16px;
}

.App-header {
  position: fixed;
  top:  0;
  left: 0;
  right: 0;
  z-index: 2;
  background: #202020e0;
  box-shadow: 0px 0px 20px 20px #202020e3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-between;
  padding: 2em 6em;
  font-size: 1em;

  h1{
    font-size: 5em;
  }
}

.Menu {
  ul{
    display:flex;
    list-style: none;
    padding: 0;
    font-size: 1.2em;
    li{
      margin: 0 .6em;
      background: none;
      
       a.link{
        text-decoration: none;
        border-bottom: 4px solid transparent;
        transition: border-bottom-color 0.4s ease;

         &:visited{
           color: white;
         }
         &:hover{
           border-bottom-color: $accent;
         }
       }

       a.button{
            padding: 0.6em 1em;
       }
    }
  }
}

.Body{
  padding: 0 6em;
}

.section{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  max-width: 1400px;
  min-height: 100vh;
  margin: 0 auto;
}

.Heading{
  font-size: 2em;
  text-align: left;
  position: relative;
  width: 100%;

    span{
      background: #202020;
      padding-right: 1em;
    }

    &::after{
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      height: 1px;
      margin-left: 1em;
      width: calc(100% - 1em);
      background-color: #0e94ff;
      z-index: -1;
    }
}

::after{
  box-sizing: inherit;
}

.button{
  background: 0;
  border: 1px solid $accent;
  color: $accent;
  padding: 0.8em 2.2em;
  border-radius: 0.4em;
  font-weight: bold;
  font-size: 1em;
  margin-top: 1em;
  text-decoration: none;
  cursor: pointer;
  outline: 0;

  &:visited{
    color: $accent !important;
  }
}



html {
  scroll-behavior: smooth;
}

body {
  background-color: $background;
  color: white;
}