@import '../../App.scss';

.Projects{
    max-width: 1000px !important;
    position: relative;

    .pacman-container{
        position: relative;
        height: 50em;
        width: 100%;
    }

    .pacman {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .pacman>div:first-of-type {
        width: 0;
        height: 0;
        border-right: 25px solid transparent;
        border-top: 25px solid $accent;
        border-left: 25px solid $accent;
        border-bottom: 25px solid $accent;
        border-radius: 25px;
        animation: rotate_pacman_half_up .5s 0s infinite;
        position: relative;
        left: -30px;
    }

    .pacman>div:nth-child(2) {
        width: 0;
        height: 0;
        border-right: 25px solid transparent;
        border-top: 25px solid $accent;
        border-left: 25px solid $accent;
        border-bottom: 25px solid $accent;
        border-radius: 25px;
        animation: rotate_pacman_half_down .5s 0s infinite;
        margin-top: -50px;
        position: relative;
        left: -30px;
    }

    .pacman>div:nth-child(3), .pacman>div:nth-child(4), .pacman>div:nth-child(5), .pacman>div:nth-child(6) {
        background-color: $accent;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        margin: 2px;
        width: 10px;
        height: 10px;
        position: absolute;
        transform: translate(0,-6.25px);
        top: 25px;
        left: 70px;
    }

    .pacman>div:nth-child(3) {
        animation: pacman-balls 1s -.66s infinite linear;
    }

    .pacman>div:nth-child(4) {
        animation: pacman-balls 1s -.33s infinite linear;
    }

    .pacman>div:nth-child(5) {
        animation: pacman-balls 1s 0s infinite linear;
    }
    
    @keyframes rotate_pacman_half_up {
        0% {
            transform: rotate(270deg)
        }
    
        50% {
            transform: rotate(360deg)
        }
    
        100% {
            transform: rotate(270deg)
        }
    }
    
    @keyframes rotate_pacman_half_down {
        0% {
            transform: rotate(90deg)
        }
    
        50% {
            transform: rotate(0deg)
        }
    
        100% {
            transform: rotate(90deg)
        }
    }
    
    @keyframes pacman-balls {
        75% {
            opacity: .7
        }
    
        100% {
            transform: translate(-100px, -6.25px)
        }
    }
}